/*!
 * Bootstrap Grid v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  box-sizing: border-box;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

@media (width >= 576px) {
  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }
}

@media (width >= 768px) {
  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }
}

@media (width >= 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}

@media (width >= 1200px) {
  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }
}

@media (width >= 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

button {
  color: #fff;
  cursor: pointer;
  background: #070707;
  border: 0;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 50px;
  margin-left: 10px;
  margin-right: 70px;
  padding: 0;
  display: inline-flex;
}

button span {
  text-transform: uppercase;
  font-weight: 400;
}

button svg {
  margin-right: 1.2em;
}

button.gradient {
  color: #070707;
  background: linear-gradient(90deg, #e8e8e8 0%, #cfcfcf 43.3%, #bcbcbc 100%);
}

button.gradient svg path {
  fill: #070707;
}

input[type="text"], input[type="email"], input[type="submit"] {
  background: none;
  border: 0;
  border-bottom: 5px solid #070707;
}

input[type="text"], input[type="email"] {
  color: #d5d5d5;
  width: 100%;
  font-size: 16px;
}

input[type="submit"] {
  text-transform: uppercase;
  color: #070707;
  cursor: pointer;
  width: 185px;
  max-width: 100%;
  font-size: 50px;
}

input[type="checkbox"] {
  appearance: none;
  background-color: #0000;
  border: 4px solid #070707;
  outline: 0;
  width: 26px;
  height: 26px;
  margin-right: 1rem;
  display: inline-block;
}

input[type="checkbox"]:focus {
  border: 3px solid #070707;
  outline: none;
}

input[type="checkbox"]:checked {
  text-align: center;
  background-color: #070707;
}

header {
  z-index: 9999;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 12px 10px;
  transition: padding .3s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (width >= 992px) {
  header {
    padding: 30px 50px 10px;
  }
}

header a, header button {
  margin-right: 50px;
}

header a {
  text-transform: uppercase;
  color: #fff;
  margin-right: 20px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  position: relative;
}

header a:last-of-type {
  margin-right: 0;
}

header a.active {
  background: #070707;
}

header a.active:before {
  content: "";
  background: #070707;
  width: 100%;
  height: 32px;
  position: absolute;
  bottom: -32px;
  left: 0;
}

header a.active:after {
  content: "";
  background: #070707;
  width: 100%;
  height: 52px;
  position: absolute;
  top: -52px;
  left: 0;
}

header a.collab.active, header a.team.active, header a.academy.active, header a.collab.active:before, header a.team.active:before, header a.academy.active:before, header a.collab.active:after, header a.team.active:after, header a.academy.active:after {
  background: #b20005;
}

header.header-dark a {
  color: #070707;
}

header.header-dark a.active {
  color: #fff;
}

header.header-dark .logo svg path, header.header-dark .hamburger svg path {
  fill: #070707;
}

header .logo {
  position: relative;
}

header .logo a {
  margin: 0;
  padding: 0;
  display: block;
}

header nav {
  align-items: center;
  display: none;
}

@media (width >= 992px) {
  header nav {
    display: flex;
  }
}

header .hamburger {
  cursor: pointer;
  width: 33px;
}

header.blurred {
  backdrop-filter: blur(10px);
  padding-top: 10px;
  padding-bottom: 5px;
}

header.blurred a.active:before {
  height: 27px;
  bottom: -27px;
}

header .mobile-menu {
  background: #070707;
  justify-content: center;
  align-items: end;
  width: 100%;
  height: 100vh;
  padding-right: 30px;
  transition: transform .3s;
  position: fixed;
  top: 0;
  left: 0;
}

header .mobile-menu .x {
  z-index: 0;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 46px;
  right: 30px;
}

header .mobile-menu .x svg path {
  fill: #fff;
}

header .mobile-menu.hide {
  transform: translateX(100%);
}

header .mobile-menu .mobile-nav-link {
  color: #fff;
  margin-bottom: 2.25rem;
  margin-right: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 900;
}

header .mobile-menu .mobile-nav-link:last-of-type {
  margin-bottom: 3.5rem;
}

header .mobile-menu a svg {
  width: 30px;
  height: 30px;
}

header .mobile-menu a svg path {
  fill: #fff;
}

footer {
  text-transform: uppercase;
  background: #fff;
  padding-top: 75px;
  padding-bottom: 75px;
}

@media (width >= 992px) {
  footer {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

footer a {
  margin-bottom: 1rem;
}

footer p {
  font-weight: 900;
}

footer img {
  width: 145px;
}

footer .social-media-link {
  line-height: 1;
}

footer .social-media-icon {
  object-fit: cover;
  width: 1.5rem;
}

.social-media-widget {
  z-index: 99;
  position: fixed;
  top: 450px;
  right: 12px;
}

@media (width >= 992px) {
  .social-media-widget {
    right: 50px;
  }
}

.social-media-widget a {
  width: 27px;
  height: 27px;
  margin-bottom: 1.25rem;
}

.social-media-widget a:last-of-type {
  margin-bottom: 0;
}

.social-media-widget svg {
  width: 100%;
  height: 100%;
}

.social-media-widget svg path {
  fill: #fff !important;
}

.social-media-widget.dark svg path {
  fill: #070707 !important;
}

#hero {
  background: #b20005;
  width: 100%;
  height: 100vh;
  position: relative;
}

#hero .container-fluid, #hero .container-fluid .row {
  height: 100%;
}

#hero h1 {
  color: #fff;
  z-index: 999;
  flex-direction: column;
  margin-bottom: 0;
  font-size: 80px;
  font-weight: 900;
  line-height: 80px;
  display: flex;
}

@media (width >= 576px) {
  #hero h1 {
    margin-top: -50px;
  }
}

@media (width >= 992px) {
  #hero h1 {
    padding-left: 50px;
    font-size: 100px;
    line-height: 100px;
  }
}

#hero .hero-footer {
  flex-direction: column-reverse;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (width >= 576px) {
  #hero .hero-footer {
    flex-direction: row;
    height: 75px;
  }
}

@media (width >= 1200px) {
  #hero .hero-footer {
    height: 100px;
  }
}

#hero .hero-footer img {
  object-fit: cover;
  width: 100%;
  height: 75px;
}

@media (width >= 576px) {
  #hero .hero-footer img {
    width: 50%;
    height: 100%;
  }
}

#hero .hero-footer .hero-desc {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1.25rem 12px;
  display: flex;
}

@media (width >= 576px) {
  #hero .hero-footer .hero-desc {
    width: 50%;
    padding-left: 50px;
  }
}

#hero .hero-footer .hero-desc span {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
}

@media (width >= 576px) {
  #hero .hero-footer .hero-desc span {
    font-size: 24px;
  }
}

#values {
  height: 200vh;
  position: relative;
}

#values .overlay {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  transition: all .25s;
  display: flex;
  position: absolute;
  top: 0;
}

#values .values-container {
  padding-top: 200px;
  padding-bottom: 200px;
  transform: translateY(100%);
}

#values .pattern {
  bottom: 0;
}

#values h2 {
  text-transform: uppercase;
  margin-bottom: 1rem;
  line-height: 1;
}

#values .value-box {
  background: #b20005;
  align-items: flex-end;
  width: 400px;
  height: 235px;
  padding: 2rem;
  display: flex;
  position: relative;
}

#values .value-box p {
  margin-bottom: 0;
  font-size: 14px;
}

#mobile-values {
  position: relative;
}

#mobile-values .pattern {
  top: 0;
}

#mobile-values h2 {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 1rem;
  line-height: 1;
}

#mobile-values .value-box {
  background: #070707;
  align-items: flex-end;
  height: 235px;
  padding: 2rem;
  display: flex;
  position: relative;
}

#mobile-values .value-box p {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
}

#about img {
  width: 45px;
  margin-right: 1rem;
  transition: opacity .25s;
}

@media (width >= 1200px) {
  #about img {
    width: 55px;
  }
}

#about h2 {
  text-transform: capitalize;
  font-weight: 900;
}

#about p {
  max-width: 100%;
  padding-left: calc(55px + 2rem);
  padding-right: 1.5rem;
}

@media (width >= 1200px) {
  #about p {
    width: 350px;
    padding-left: calc(55px + 1rem);
    padding-right: 0;
  }
}

#businessAreas {
  background: #fff;
}

@media (width >= 992px) {
  #businessAreas {
    height: 2500px;
  }
}

#businessAreas .fade-in-text {
  margin: 50px 0 100px;
  font-size: 34px;
  line-height: 1.25;
}

#businessAreas .fade-in-text br {
  display: none;
}

@media (width >= 576px) {
  #businessAreas .fade-in-text br {
    display: block;
  }
}

@media (width >= 992px) {
  #businessAreas .fade-in-text {
    font-size: 64px;
    line-height: 64px;
  }
}

#businessAreas .mobile-balls-wrapper .mobile-ball {
  background: #070707;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  margin-bottom: 1.5rem;
  display: flex;
  position: relative;
}

#businessAreas .mobile-balls-wrapper .mobile-ball-content {
  margin-bottom: 1.5rem;
}

#businessAreas .balls-wrapper {
  margin-bottom: 150px;
}

#businessAreas .balls-wrapper .ball {
  background: #070707;
  border-radius: 50%;
  display: flex;
  position: relative;
}

#businessAreas .balls-wrapper .ball .ball-content {
  align-items: center;
  width: 100vw;
  display: flex;
  position: absolute;
}

#businessAreas .balls-wrapper .ball-1 {
  width: 46px;
  height: 46px;
}

#businessAreas .balls-wrapper .ball-1 .ball-content {
  top: -1.75rem;
  left: 2.5rem;
}

#businessAreas .balls-wrapper .ball-2 {
  width: 124px;
  height: 124px;
}

#businessAreas .balls-wrapper .ball-2 .ball-content {
  top: -.5rem;
  left: 7rem;
}

#businessAreas .balls-wrapper .ball-3 {
  width: 220px;
  height: 220px;
}

#businessAreas .balls-wrapper .ball-3 .ball-content {
  top: 0;
  left: -29.5rem;
}

#businessAreas .balls-wrapper .ball-3 .placeholder {
  width: 220px;
  height: 220px;
}

#businessAreas .balls-wrapper .ball-3 p {
  width: 19rem;
}

#businessAreas .balls-wrapper .ball-4 {
  width: 317px;
  height: 317px;
}

#businessAreas .balls-wrapper .ball-4 .ball-content {
  top: 5.5rem;
  left: -47rem;
}

#businessAreas .ball-content-wrappers {
  height: 140vh;
  position: relative;
}

#businessAreas .ball-content-wrapper {
  align-items: center;
  display: flex;
  position: absolute;
}

#businessAreas .ball-content-wrapper:first-of-type {
  top: 0;
  left: 3rem;
}

#businessAreas .ball-content-wrapper:nth-of-type(2) {
  top: 12rem;
  left: 24rem;
}

#businessAreas .ball-content-wrapper:nth-of-type(3) {
  top: 25rem;
  left: 8.5rem;
}

#businessAreas .ball-content-wrapper:nth-of-type(3) .placeholder {
  width: 220px;
  height: 220px;
}

#businessAreas .ball-content-wrapper:nth-of-type(3) p {
  width: 19rem;
}

#businessAreas .ball-content-wrapper:nth-of-type(4) {
  bottom: 8rem;
  right: 20rem;
}

#businessAreas .ball-content-wrapper h2 {
  line-height: 1;
}

#businessAreas .ball-content-wrapper p {
  margin: .5rem 0 0;
}

#core-team {
  margin-top: -1px;
}

#core-team .pattern {
  height: calc(100% - 180px);
  top: 100px;
}

#core-team .core-team-box {
  background: #b20005;
  flex-direction: column;
  padding: 0;
  display: flex;
  position: relative;
}

@media (width >= 992px) {
  #core-team .core-team-box {
    padding: 25px 50px;
  }
}

#core-team .core-team-box .core-team-member {
  background-blend-mode: multiply;
  background-color: #b20005;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;
  width: 225px;
  height: 300px;
  padding-left: 1rem;
  transition: background-color .3s;
  display: flex;
}

@media (width >= 992px) {
  #core-team .core-team-box .core-team-member {
    width: 225px;
    height: 300px;
  }
}

#core-team .core-team-box .core-team-member:hover {
  background-color: #0000;
}

#core-team .core-team-box .core-team-member.avatar {
  background-image: url("avatar.b1998510.jpeg");
}

#core-team .core-team-box .core-team-member.kamil {
  background-image: url("kamil.5a975b5f.jpeg");
}

#core-team .core-team-box .core-team-member.wiktor {
  background-image: url("wiktor.d987f1ae.jpeg");
}

#core-team .core-team-box .core-team-member.bartek {
  background-image: url("bartek.26a23914.jpeg");
}

#core-team .core-team-box .core-team-member h3, #core-team .core-team-box .core-team-member p {
  color: #fff;
  text-transform: uppercase;
}

#core-team .core-team-box .core-team-member h3 {
  margin-bottom: 0;
  font-size: 18px;
}

@media (width >= 992px) {
  #core-team .core-team-box .core-team-member h3 {
    font-size: 26px;
  }
}

#core-team .core-team-box .core-team-member p {
  font-size: 12px;
  font-weight: 900;
}

@media (width >= 992px) {
  #core-team .core-team-box .core-team-member p {
    font-size: 14px;
  }
}

#core-team .core-team-top, #core-team .core-team-bottom {
  width: 100%;
}

#core-team .core-team-top {
  flex-direction: column;
  display: flex;
}

@media (width >= 992px) {
  #core-team .core-team-top {
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 25px;
  }
}

#core-team .core-team-top .core-team-copy {
  flex-direction: column;
  display: flex;
}

@media (width >= 992px) {
  #core-team .core-team-top .core-team-copy {
    align-items: flex-end;
    padding-top: 50px;
    padding-right: 50px;
  }

  #core-team .core-team-top .core-team-copy h2 {
    font-size: 70px;
    line-height: 1;
  }
}

#core-team .core-team-top .core-team-zebra {
  width: 330px;
  height: 45px;
  margin-bottom: 1.5rem;
  display: none;
}

@media (width >= 992px) {
  #core-team .core-team-top .core-team-zebra {
    margin-bottom: 0;
    display: block;
  }
}

#core-team .core-team-bottom {
  justify-content: space-between;
  align-items: flex-end;
}

#core-team .core-team-bottom span {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 700;
}

#core-team .core-team-bottom span a {
  color: #fff;
  font-weight: 900;
  text-decoration: underline;
}

@media (width >= 992px) {
  #core-team .core-team-bottom span {
    font-size: 30px;
  }
}

#core-team .core-team-see-all {
  text-transform: uppercase;
  margin-bottom: 50px;
  font-size: 21px;
  font-weight: 700;
}

#core-team .core-team-see-all a {
  color: #fff;
  font-weight: 900;
  text-decoration: underline;
}

@media (width >= 992px) {
  #core-team .core-team-see-all {
    font-size: 30px;
  }
}

#games-page #games-hero {
  padding-top: 175px;
}

@media (width >= 992px) {
  #games-page #games-hero {
    padding-top: 250px;
  }
}

#games-page #games-content {
  overflow: visible;
}

#games-page #games-content .sidebar {
  border-left: 5px solid #d5d5d5;
  height: 100vh;
  padding-top: 150px;
  padding-left: 1.5rem;
  position: sticky;
  top: 80px;
}

#games-page #games-content .sidebar h3 {
  color: #d5d5d5;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  margin-bottom: 100px;
  display: flex;
  position: relative;
}

#games-page #games-content .sidebar h3.active {
  color: #070707;
}

#games-page #games-content .sidebar h3.active:before {
  content: "";
  background: #b20005;
  width: 5px;
  height: 100%;
  position: absolute;
  left: calc(-1.5rem - 5px);
}

#games-page #games-content .pallaside, #games-page #games-content .fame, #games-page #games-content .ptoe {
  background-repeat: no-repeat;
  background-size: cover;
  align-items: end;
  width: 100%;
  max-width: 100%;
  padding: 3rem 3rem 4rem;
  display: flex;
  position: relative;
}

@media (width >= 992px) {
  #games-page #games-content .pallaside, #games-page #games-content .fame, #games-page #games-content .ptoe {
    height: 600px;
  }
}

#games-page #games-content .pallaside h2, #games-page #games-content .pallaside p, #games-page #games-content .pallaside span, #games-page #games-content .fame h2, #games-page #games-content .fame p, #games-page #games-content .fame span, #games-page #games-content .ptoe h2, #games-page #games-content .ptoe p, #games-page #games-content .ptoe span {
  color: #fff;
}

#games-page #games-content .pallaside h2, #games-page #games-content .fame h2, #games-page #games-content .ptoe h2 {
  text-transform: initial;
}

#games-page #games-content .pallaside p, #games-page #games-content .fame p, #games-page #games-content .ptoe p {
  font-size: 14px;
}

#games-page #games-content .pallaside span, #games-page #games-content .fame span, #games-page #games-content .ptoe span {
  text-transform: uppercase;
}

#games-page #games-content .pallaside .play, #games-page #games-content .fame .play, #games-page #games-content .ptoe .play {
  width: 75px;
  margin-right: 1rem;
}

#games-page #games-content .pallaside .stores, #games-page #games-content .fame .stores, #games-page #games-content .ptoe .stores {
  flex-direction: column;
}

@media (width >= 576px) {
  #games-page #games-content .pallaside .stores, #games-page #games-content .fame .stores, #games-page #games-content .ptoe .stores {
    flex-direction: row;
  }
}

#games-page #games-content .pallaside .stores img, #games-page #games-content .fame .stores img, #games-page #games-content .ptoe .stores img {
  width: 150px;
  max-width: 100%;
  margin-bottom: 1rem;
}

@media (width >= 576px) {
  #games-page #games-content .pallaside .stores img, #games-page #games-content .fame .stores img, #games-page #games-content .ptoe .stores img {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

#games-page #games-content .pallaside, #games-page #games-content .fame {
  margin-bottom: 100px;
}

#games-page #games-content .pallaside {
  background-image: url("pallaside-mobile.97e2f077.jpeg");
}

@media (width >= 576px) {
  #games-page #games-content .pallaside {
    background-image: url("pallaside.853fef98.jpeg");
  }
}

#games-page #games-content .fame {
  background-image: url("fame-mobile.9c43fdc0.jpeg");
  padding-top: 6rem;
}

@media (width >= 992px) {
  #games-page #games-content .fame {
    background-image: url("fame.d64e7492.jpeg");
    padding-top: 3rem;
  }
}

#games-page #games-content .ptoe {
  background-image: url("p2e.5786588d.jpeg");
  padding-top: 6rem;
  padding-bottom: 6rem;
}

#collab-page {
  background-color: #070707;
  background-image: url("collab-bg.eca013c9.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

#collab-page h2, #collab-page p {
  color: #fff;
}

#collab-page #collab-hero {
  padding-top: 175px;
}

@media (width >= 992px) {
  #collab-page #collab-hero {
    height: 100vh;
    padding-top: 250px;
  }
}

#collab-page #collab-hero h1 {
  color: #fff;
}

#collab-page .pattern {
  top: 0;
}

#collab-page .collab-box {
  position: relative;
}

#collab-page .collab-box .collab-box-text {
  padding-left: 50px;
}

#collab-page .collab-box a {
  display: flex;
}

#collab-page .collab-box p {
  font-size: 20px;
}

#collab-page .collab-box .kamil {
  background-blend-mode: multiply;
  background-color: #b20005;
  background-image: url("kamil.5a975b5f.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 175px;
  height: 225px;
}

#collab-page .collab-box .zebra-col {
  align-items: flex-end;
  height: 125px;
  display: flex;
}

#collab-page .collab-box .zebra {
  width: 330px;
  height: 45px;
  margin-left: 50px;
}

#collab-page .collab-box .linkedin, #collab-page .collab-box .twitter {
  height: 25px;
}

#collab-page .collab-box .mail {
  height: 31px;
  margin-bottom: -5px;
}

#utility-page {
  background: #b20005;
}

#utility-page p, #utility-page li, #utility-page label {
  font-size: 16px;
}

#utility {
  padding-top: 175px;
}

@media (width >= 992px) {
  #utility {
    padding-top: 250px;
  }
}

#utility .hero-top-img {
  position: absolute;
  top: 0;
  right: 0;
}

@media (width >= 992px) {
  #utility .hero-top-img {
    top: -200px;
    right: 133px;
  }
}

#utility .hero-bottom-img {
  position: absolute;
  bottom: 0;
  left: 0;
}

#utility h1, #utility h2, #utility p, #utility li {
  color: #fff;
  position: relative;
}

#utility ul p {
  text-transform: uppercase;
}

#utility h1 {
  margin-bottom: 100px;
}

@media (width >= 992px) {
  #utility h1 {
    margin-bottom: 200px;
  }
}

#utility h2 {
  text-transform: uppercase;
  flex-direction: column;
  justify-content: flex-end;
  line-height: 1;
  display: flex;
}

#utility .box-wrapper h2 {
  height: 120px;
}

#utility-contact .pattern {
  top: 0;
}

#utility-contact .ball {
  width: 45px;
  height: 45px;
}

#utility-contact p {
  color: #fff;
  margin-right: 3rem;
}

#utility-contact p.iam {
  position: absolute;
  top: -4rem;
}

#utility-contact img {
  height: 29px;
  margin-right: 2rem;
}

#utility-contact label {
  color: #fff;
}

#team-page #team-hero {
  padding-top: 175px;
}

@media (width >= 992px) {
  #team-page #team-hero {
    padding-top: 250px;
  }
}

#team-page #team-hero h1 {
  color: #fff;
}

#team-page #team-people {
  margin-top: -100px;
}

#team-page .pattern {
  top: 115px;
}

#academy-page {
  padding-top: 150px;
}

#academy-page #academy-hero {
  background: #070707 url("hero.fcc9bda6.jpeg") 0 0 / cover no-repeat;
  height: 100vh;
  padding-top: 100px;
}

@media (width >= 1200px) {
  #academy-page #academy-hero {
    height: 1200px;
  }
}

#academy-page #academy-hero h1 {
  color: #fff;
}

#academy-page .pattern {
  height: calc(99vh + 100px);
  bottom: 0;
}

#academy-page #academy-content {
  margin-top: -200px;
}

#documents-page #documents-hero {
  padding-top: 175px;
}

@media (width >= 992px) {
  #documents-page #documents-hero {
    padding-top: 250px;
  }
}

.breadcrumbs {
  margin-bottom: 2rem;
}

.breadcrumbs a {
  color: #b20005;
  text-transform: uppercase;
  font-weight: 700;
}

.privacy-policy {
  padding-top: 175px;
}

@media (width >= 992px) {
  .privacy-policy {
    padding-top: 250px;
  }
}

#facebook-data-deletion-instructions-page #facebook-data-deletion-instructions {
  padding-top: 175px;
}

@media (width >= 992px) {
  #facebook-data-deletion-instructions-page #facebook-data-deletion-instructions {
    padding-top: 250px;
  }
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-ThinIta.a70cb525.eot");
  src: local(Helvetica Now Display Thin Ita), local(HelveticaNowDisplay-ThinIta), url("HelveticaNowDisplay-ThinIta.a70cb525.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-ThinIta.2c35bae0.woff2") format("woff2"), url("HelveticaNowDisplay-ThinIta.d0063321.woff") format("woff"), url("HelveticaNowDisplay-ThinIta.5ed447dd.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-Light.7dc1301b.eot");
  src: local(Helvetica Now Display Light), local(HelveticaNowDisplay-Light), url("HelveticaNowDisplay-Light.7dc1301b.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-Light.f45c6c91.woff2") format("woff2"), url("HelveticaNowDisplay-Light.1214e795.woff") format("woff"), url("HelveticaNowDisplay-Light.72486d8c.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-BoldIta.ea13af6a.eot");
  src: local(Helvetica Now Display Bold Ita), local(HelveticaNowDisplay-BoldIta), url("HelveticaNowDisplay-BoldIta.ea13af6a.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-BoldIta.8555236e.woff2") format("woff2"), url("HelveticaNowDisplay-BoldIta.053e7cb5.woff") format("woff"), url("HelveticaNowDisplay-BoldIta.86342cab.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-RegIta.002c0669.eot");
  src: local(Helvetica Now Display Reg Ita), local(HelveticaNowDisplay-RegIta), url("HelveticaNowDisplay-RegIta.002c0669.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-RegIta.728ea02e.woff2") format("woff2"), url("HelveticaNowDisplay-RegIta.328d84e5.woff") format("woff"), url("HelveticaNowDisplay-RegIta.a44a96b8.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-ExtLtIta.fa69e91b.eot");
  src: local(Helvetica Now Display ExtLt Ita), local(HelveticaNowDisplay-ExtLtIta), url("HelveticaNowDisplay-ExtLtIta.fa69e91b.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-ExtLtIta.c9099e22.woff2") format("woff2"), url("HelveticaNowDisplay-ExtLtIta.91a553de.woff") format("woff"), url("HelveticaNowDisplay-ExtLtIta.583ec0a8.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-MedIta.bb3f6472.eot");
  src: local(Helvetica Now Display Med Ita), local(HelveticaNowDisplay-MedIta), url("HelveticaNowDisplay-MedIta.bb3f6472.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-MedIta.1385c1c1.woff2") format("woff2"), url("HelveticaNowDisplay-MedIta.7feeb7ee.woff") format("woff"), url("HelveticaNowDisplay-MedIta.f5391638.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-ExtLt.31a73899.eot");
  src: local(Helvetica Now Display ExtLt), local(HelveticaNowDisplay-ExtLt), url("HelveticaNowDisplay-ExtLt.31a73899.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-ExtLt.441b1792.woff2") format("woff2"), url("HelveticaNowDisplay-ExtLt.28737f49.woff") format("woff"), url("HelveticaNowDisplay-ExtLt.b343925c.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-ExtBlk.5ab68f56.eot");
  src: local(Helvetica Now Display ExtBlk), local(HelveticaNowDisplay-ExtBlk), url("HelveticaNowDisplay-ExtBlk.5ab68f56.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-ExtBlk.2a955001.woff2") format("woff2"), url("HelveticaNowDisplay-ExtBlk.080ab100.woff") format("woff"), url("HelveticaNowDisplay-ExtBlk.669c4db1.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-Bold.0a9aeb16.eot");
  src: local(Helvetica Now Display Bold), local(HelveticaNowDisplay-Bold), url("HelveticaNowDisplay-Bold.0a9aeb16.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-Bold.c59c6246.woff2") format("woff2"), url("HelveticaNowDisplay-Bold.2e00ca4c.woff") format("woff"), url("HelveticaNowDisplay-Bold.4ca21d44.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-ExtBdIta.7fab1a24.eot");
  src: local(Helvetica Now Display ExtBd Ita), local(HelveticaNowDisplay-ExtBdIta), url("HelveticaNowDisplay-ExtBdIta.7fab1a24.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-ExtBdIta.b22ad623.woff2") format("woff2"), url("HelveticaNowDisplay-ExtBdIta.2ffe3038.woff") format("woff"), url("HelveticaNowDisplay-ExtBdIta.36ad3f56.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-Regular.7480f1d3.eot");
  src: local(Helvetica Now Display Regular), local(HelveticaNowDisplay-Regular), url("HelveticaNowDisplay-Regular.7480f1d3.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-Regular.ec407bad.woff2") format("woff2"), url("HelveticaNowDisplay-Regular.9b73f4db.woff") format("woff"), url("HelveticaNowDisplay-Regular.0ef02071.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-ExtBlkIta.a77897bf.eot");
  src: local(Helvetica Now Display ExBlk It), local(HelveticaNowDisplay-ExtBlkIta), url("HelveticaNowDisplay-ExtBlkIta.a77897bf.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-ExtBlkIta.b1a7635e.woff2") format("woff2"), url("HelveticaNowDisplay-ExtBlkIta.04c2e5dd.woff") format("woff"), url("HelveticaNowDisplay-ExtBlkIta.eb62e948.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-Thin.7070021c.eot");
  src: local(Helvetica Now Display Thin), local(HelveticaNowDisplay-Thin), url("HelveticaNowDisplay-Thin.7070021c.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-Thin.04ae5a37.woff2") format("woff2"), url("HelveticaNowDisplay-Thin.dbdfd2ec.woff") format("woff"), url("HelveticaNowDisplay-Thin.dd3092cb.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-LightIta.2de14db3.eot");
  src: local(Helvetica Now Display Light Ita), local(HelveticaNowDisplay-LightIta), url("HelveticaNowDisplay-LightIta.2de14db3.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-LightIta.599b4f1e.woff2") format("woff2"), url("HelveticaNowDisplay-LightIta.9bb40316.woff") format("woff"), url("HelveticaNowDisplay-LightIta.e19e3729.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-ExtraBold.8885f083.eot");
  src: local(Helvetica Now Display ExtBd), local(HelveticaNowDisplay-ExtraBold), url("HelveticaNowDisplay-ExtraBold.8885f083.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-ExtraBold.dd3dc1a5.woff2") format("woff2"), url("HelveticaNowDisplay-ExtraBold.59a66763.woff") format("woff"), url("HelveticaNowDisplay-ExtraBold.66492d70.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-Medium.2a88d356.eot");
  src: local(Helvetica Now Display Medium), local(HelveticaNowDisplay-Medium), url("HelveticaNowDisplay-Medium.2a88d356.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-Medium.a43b064b.woff2") format("woff2"), url("HelveticaNowDisplay-Medium.64ec9762.woff") format("woff"), url("HelveticaNowDisplay-Medium.fe6248eb.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-HairlineI.0c09ca79.eot");
  src: local(Helvetica Now Display Hairline), local(HelveticaNowDisplay-HairlineI), url("HelveticaNowDisplay-HairlineI.0c09ca79.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-HairlineI.d68f0389.woff2") format("woff2"), url("HelveticaNowDisplay-HairlineI.3b0a51f0.woff") format("woff"), url("HelveticaNowDisplay-HairlineI.b154d3c6.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-BlackIta.28074f81.eot");
  src: local(Helvetica Now Display Black Ita), local(HelveticaNowDisplay-BlackIta), url("HelveticaNowDisplay-BlackIta.28074f81.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-BlackIta.986da574.woff2") format("woff2"), url("HelveticaNowDisplay-BlackIta.b09c1347.woff") format("woff"), url("HelveticaNowDisplay-BlackIta.617982bb.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-Black.2dad9332.eot");
  src: local(Helvetica Now Display Black), local(HelveticaNowDisplay-Black), url("HelveticaNowDisplay-Black.2dad9332.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-Black.1a1e2cf4.woff2") format("woff2"), url("HelveticaNowDisplay-Black.ed118ae2.woff") format("woff"), url("HelveticaNowDisplay-Black.6bc17a4c.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Helvetica Now Display;
  src: url("HelveticaNowDisplay-Hairline.df5210b6.eot");
  src: local(Helvetica Now Display Hairline), local(HelveticaNowDisplay-Hairline), url("HelveticaNowDisplay-Hairline.df5210b6.eot#iefix") format("embedded-opentype"), url("HelveticaNowDisplay-Hairline.741860db.woff2") format("woff2"), url("HelveticaNowDisplay-Hairline.13815b3b.woff") format("woff"), url("HelveticaNowDisplay-Hairline.a0a02468.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: Helvetica Now Display;
}

body {
  letter-spacing: 1px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
  max-width: 100% !important;
}

@media (width >= 992px) {
  section {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }
}

p {
  margin-top: 0;
}

p br {
  display: none;
}

@media (width >= 992px) {
  p br {
    display: block;
  }
}

a {
  color: #070707;
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: 400;
}

h1 {
  font-size: 38px;
  line-height: 1.25;
}

h1 br {
  display: none;
}

@media (width >= 576px) {
  h1 {
    font-size: 50px;
    line-height: 50px;
  }

  h1 br {
    display: block;
  }
}

@media (width >= 1200px) {
  h1 {
    font-size: 70px;
    line-height: 70px;
  }
}

h2 {
  text-transform: uppercase;
  font-size: 28px;
}

h2 br {
  display: none;
}

@media (width >= 1200px) {
  h2 {
    font-size: 40px;
  }

  h2 br {
    display: block;
  }
}

h3 {
  text-transform: uppercase;
  font-size: 21px;
}

@media (width >= 576px) {
  h3 {
    font-size: 31px;
  }
}

ul {
  list-style-image: url("li.dfce2820.svg");
}

ul p, ul li {
  padding-left: .5rem;
}

ul li, ol li {
  margin-bottom: 1rem;
}

ol.lower-alpha {
  list-style: lower-alpha;
}

ol.nested-counter-list {
  counter-reset: item;
}

ol.nested-counter-list li {
  display: block;
}

ol.nested-counter-list li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  font-weight: bold;
}

ol.nested-counter-list ol {
  counter-reset: item;
}

ol.nested-counter-list ol > li {
  display: block;
}

ol.nested-counter-list ol > li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  font-weight: bold;
}

.position-relative {
  position: relative;
}

.w-10 {
  width: 100%;
}

@media (width >= 992px) {
  .w-10 {
    width: 10%;
  }
}

.w-20 {
  width: 100%;
}

@media (width >= 992px) {
  .w-20 {
    width: 20%;
  }
}

.w-30 {
  width: 100%;
}

@media (width >= 992px) {
  .w-30 {
    width: 30%;
  }
}

.w-40 {
  width: 100%;
}

@media (width >= 992px) {
  .w-40 {
    width: 40%;
  }
}

.w-50 {
  width: 100%;
}

@media (width >= 992px) {
  .w-50 {
    width: 50%;
  }
}

.w-60 {
  width: 100%;
}

@media (width >= 992px) {
  .w-60 {
    width: 60%;
  }
}

.w-70 {
  width: 100%;
}

@media (width >= 992px) {
  .w-70 {
    width: 70%;
  }
}

.w-80 {
  width: 100%;
}

@media (width >= 992px) {
  .w-80 {
    width: 80%;
  }
}

.w-90 {
  width: 100%;
}

@media (width >= 992px) {
  .w-90 {
    width: 90%;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mb-50 {
  margin-bottom: 25px;
}

@media (width >= 1200px) {
  .mb-50 {
    margin-bottom: 50px;
  }
}

.mb-100 {
  margin-bottom: 50px;
}

@media (width >= 1200px) {
  .mb-100 {
    margin-bottom: 100px;
  }
}

.mb-200 {
  margin-bottom: 200px;
}

.mr-8 {
  margin-right: 8rem;
}

.text-black {
  color: #070707 !important;
}

.text-white {
  color: #fff !important;
}

.text-red {
  color: #b20005 !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.font-size-14 {
  font-size: 14px;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-900 {
  font-weight: 900;
}

.bg-black h2, .bg-black p {
  color: #fff;
}

.bg-white {
  background: #fff !important;
}

.bg-black {
  background: #070707 !important;
}

.bg-red {
  background: #b20005 !important;
}

.mobile-overflow-hidden {
  overflow: hidden;
}

@media (width >= 992px) {
  .mobile-overflow-hidden {
    overflow: auto;
  }
}

.slideTextTrigger {
  overflow-x: hidden;
}

.slideTextTrigger .animeTextLeft, .slideTextTrigger .animeTextRight {
  text-transform: uppercase;
  font-size: 31px;
}

@media (width >= 576px) {
  .slideTextTrigger .animeTextLeft, .slideTextTrigger .animeTextRight {
    font-size: 38px;
  }
}

.slideTextTrigger .animeTextLeft, .slideTextTrigger .animeTextRight {
  white-space: nowrap;
  transition: transform .25s;
}

.pattern {
  background-image: url("pattern.49190e80.svg");
  background-position: center;
  width: calc(100% - 24px);
  height: calc(100% + 100px);
  position: absolute;
}

.pattern.pattern-dark {
  background-image: url("pattern-dark.b9c4dc9d.svg");
}

@media (width >= 992px) {
  .pattern {
    background-size: contain;
    width: calc(100% - 100px);
    height: calc(100% + 200px);
  }
}

.spacer {
  flex-direction: column-reverse;
  width: 100%;
  display: flex;
}

@media (width >= 576px) {
  .spacer {
    flex-direction: row;
    height: 75px;
  }
}

@media (width >= 1200px) {
  .spacer {
    height: 100px;
  }
}

.spacer img {
  object-fit: cover;
  width: 100%;
  height: 75px;
}

@media (width >= 576px) {
  .spacer img {
    width: 50%;
    height: 100%;
  }
}

.spacer .spacer-desc {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1.25rem 12px;
  display: flex;
}

@media (width >= 576px) {
  .spacer .spacer-desc {
    width: 50%;
    padding-left: 50px;
  }
}

.spacer .spacer-desc span {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
}

@media (width >= 576px) {
  .spacer .spacer-desc span {
    font-size: 24px;
  }
}

.box-wrapper .box {
  align-items: flex-end;
  height: 170px;
  margin-top: 1rem;
  padding: 2rem;
  display: flex;
}

.box-wrapper .box p {
  margin-bottom: 0;
  font-size: 14px;
}

.team-wrapper {
  text-transform: uppercase;
  width: 275px;
  max-width: 100%;
  margin: 0 0 4rem;
}

.team-wrapper h3 {
  margin-bottom: .5rem;
  font-size: 30px;
}

.team-wrapper .box {
  background: #b20005;
  position: relative;
}

.team-wrapper .box p {
  font-size: 14px;
}

.team-wrapper .box .team-member-photo {
  background-blend-mode: multiply;
  background-color: #b20005;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 325px;
  padding-left: .75rem;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

.team-wrapper .box .team-member-photo:hover {
  background-color: #0000;
}

.team-wrapper .box .team-member-photo.kamil {
  background-image: url("kamil.5a975b5f.jpeg");
}

.team-wrapper .box .team-member-photo.wiktor {
  background-image: url("wiktor.d987f1ae.jpeg");
}

.team-wrapper .box .team-member-photo.bartek {
  background-image: url("bartek.26a23914.jpeg");
}

.team-wrapper .box .team-member-photo.mike {
  background-image: url("mike.d84ef7ad.jpeg");
}

.team-wrapper .box .team-member-photo.annbarass {
  background-image: url("annbarass.1284e2e6.jpeg");
}

.team-wrapper .box .team-member-photo.avatar {
  background-image: url("avatar.b1998510.jpeg");
}

.team-wrapper .box .team-member-photo h3, .team-wrapper .box .team-member-photo p {
  color: #fff;
  text-transform: uppercase;
}

.team-wrapper .box .team-member-photo h3 {
  margin-bottom: 0;
  font-size: 26px;
}

.team-wrapper .box .team-member-photo p {
  font-weight: 900;
}

.team-wrapper .box .box-footer {
  padding: 1.5rem .75rem;
}

.team-wrapper .box .box-footer p {
  margin-bottom: 0;
}

.team-wrapper .box .box-footer .box-social-media {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 8.25rem;
  right: 0;
}

.team-wrapper .box .box-footer .box-social-media a {
  margin-right: .75rem;
}

.team-wrapper .box .box-footer .box-social-media img {
  height: 1rem;
}

.team-wrapper .box .box-footer .box-social-media img.email {
  height: 1.35rem;
}

.contact-box {
  background: #b20005;
  padding: 2rem;
  position: relative;
}

@media (width >= 992px) {
  .contact-box {
    height: 320px;
    padding: 0;
    display: flex;
  }
}

.contact-box .contact-box-text {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: flex;
}

@media (width >= 992px) {
  .contact-box .contact-box-text {
    width: 50%;
    padding-left: 50px;
  }

  .contact-box .contact-box-text p {
    font-size: 20px;
  }
}

.contact-box .contact-box-contact {
  width: 100%;
}

@media (width >= 992px) {
  .contact-box .contact-box-contact {
    width: 50%;
    height: 225px;
    padding-left: 50px;
    display: flex;
  }
}

.contact-box .contact-box-contact .contact-box-image {
  background-blend-mode: multiply;
  background-color: #b20005;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 175px;
  height: 225px;
  margin-bottom: 1rem;
}

@media (width >= 992px) {
  .contact-box .contact-box-contact .contact-box-image {
    margin-bottom: 0;
  }
}

.contact-box .contact-box-contact .contact-box-image.kamil {
  background-image: url("kamil.5a975b5f.jpeg");
}

.contact-box .contact-box-contact .contact-box-image.wiktor {
  background-image: url("wiktor.d987f1ae.jpeg");
}

.contact-box .contact-box-contact .linkedin, .contact-box .contact-box-contact .twitter {
  height: 25px;
}

.contact-box .contact-box-contact .mail {
  height: 31px;
  margin-bottom: -5px;
}

.contact-box .contact-zebra {
  width: 330px;
  max-width: 100%;
  height: 45px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (width >= 992px) {
  .contact-box .contact-zebra {
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 50px;
  }
}

.cky-consent-container {
  width: 535px !important;
}

.cky-btn-revisit-wrapper .cky-btn-revisit {
  margin: 0;
}
/*# sourceMappingURL=index.9c62b83e.css.map */
