.social-media-widget {
  position: fixed;
  right: 12px;
  top: 450px;
  z-index: 99;
  @include media-breakpoint-up(lg) {
    right: 50px;
  }
  a {
    margin-bottom: 1.25rem;
    width: 27px;
    height: 27px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: $white !important;
    }
  }
  &.dark {
    svg {
      path {
        fill: $black !important;
      }
    }
  }
}