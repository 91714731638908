input[type=text], input[type=email], input[type=submit] {
  border: 0;
  border-bottom: 5px solid $black;
  background: transparent;
}

input[type=text], input[type=email] {
  width: 100%;
  color: $gray;
  font-size: 16px;
}

input[type=submit] {
  text-transform: uppercase;
  width: 185px;
  max-width: 100%;
  color: $black;
  font-size: 50px;
  cursor: pointer;
}

input[type=checkbox] {
  width: 26px;
  height: 26px;
  appearance: none;
  margin-right: 1rem;
  background-color: transparent;
  outline: 0;
  border: 4px solid $black;
  display: inline-block;
}

input[type=checkbox]:focus {
  outline: none;
  border: 3px solid $black;
}

input[type=checkbox]:checked {
  background-color: $black;
  text-align: center;
}