button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 50px;
  width: 155px;
  margin-right: 70px;
  margin-left: 10px;
  padding: 0;
  border: 0;
  background: $black;
  color: $white;
  cursor: pointer;
  span {
    font-weight: 400;
    text-transform: uppercase;
  }
  svg {
    margin-right: 1.2em;
  }
  &.gradient {
    background: $steel-gradient;
    color: $black;
    svg {
      path {
        fill: $black;
      }
    }
  }
}