footer {
  padding-top: 75px;
  padding-bottom: 75px;
  text-transform: uppercase;
  background: $white;
  @include media-breakpoint-up(lg) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  a {
    margin-bottom: 1rem;
  }
  p {
    font-weight: 900;
  }
  img {
    width: 145px;
  }
  .social-media-link {
    line-height: 1;
  }
  .social-media-icon {
    width: 1.5rem;
    object-fit: cover;
  }
}