#games-page {
  #games-hero {
    padding-top: 175px;
    @include media-breakpoint-up(lg) {
      padding-top: 250px;
    }
  }
  #games-content {
    overflow: visible;
    .sidebar {
      position: sticky;
      top: 80px;
      padding-top: 150px;
      padding-left: 1.5rem;
      height: 100vh;
      border-left: 5px solid $gray;
      h3 {
        position: relative;
        color: $gray;
        margin-bottom: 100px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        &.active {
          color: $black;
          &::before {
            position: absolute;
            content: '';
            width: 5px;
            height: 100%;
            background: $red;
            left: calc(-1.5rem - 5px);
          }
        }
      }
    }
    .pallaside, .fame, .ptoe {
      display: flex;
      align-items: end;
      position: relative;
      width: 100%;
      max-width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 3rem 3rem 4rem;
      @include media-breakpoint-up(lg) {
        height: 600px;
      }
      h2, p, span {
        color: $white;
      }
      h2 {
        text-transform: initial;
      }
      p {
        font-size: 14px;
      }
      span {
        text-transform: uppercase;
      }
      .play {
        width: 75px;
        margin-right: 1rem;
      }
      .stores {
        flex-direction: column;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
        }
        img {
          width: 150px;
          max-width: 100%;
          margin-bottom: 1rem;
          @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            margin-right: 1rem;
          }
        }
      } 
    }
    .pallaside, .fame {
      margin-bottom: 100px;
    }
    .pallaside {
      background-image: url("~/src/images/games/pallaside-mobile.jpeg");
      @include media-breakpoint-up(sm) {
        background-image: url("~/src/images/games/pallaside.jpeg");

      }
    }
    .fame {
      padding-top: 6rem;
      background-image: url("~/src/images/games/fame-mobile.jpeg");
      @include media-breakpoint-up(lg) {
        padding-top: 3rem;
        background-image: url("~/src/images/games/fame.jpeg");
      }
    }
    .ptoe {
      padding-top: 6rem;
      padding-bottom: 6rem;
      background-image: url("~/src/images/games/p2e.jpeg");
    }
  }
}
