#academy-page {
  padding-top: 150px;

  #academy-hero {
    padding-top: 100px;
    height: 100vh;
    background: $black;
    background-image: url("~/src/images/academy/hero.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(xl) {
      height: 1200px;
    }
    h1 {
      color: $white;
    }
  }

  .pattern {
    bottom: 0;
    height: calc(99vh + 100px);
  }

  #academy-content {
    margin-top: -200px;
  }
}