#utility-page {
  background: $red;
  p, li, label {
    font-size: 16px;
  }
}

#utility {
  padding-top: 175px;
  @include media-breakpoint-up(lg) {
    padding-top: 250px;
  }
  .hero-top-img {
    position: absolute;
    top: 0;
    right: 0;
    @include media-breakpoint-up(lg) {
      top: -200px;
      right: 133px;
    }
  }
  .hero-bottom-img {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  h1, h2, p, li {
    position: relative;
    color: $white;
  }
  ul {
    p {
      text-transform: uppercase;
    }
  }
  h1 {
    margin-bottom: 100px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 200px;
    }

  }
  h2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    line-height: 1;
    text-transform: uppercase;
  }
  .box-wrapper {
    h2 {
      height: 120px;
    }
  }
}

#utility-contact {
  // padding-top: 100px;
  // padding-bottom: 400px;
  .pattern {
    top: 0;
  }
  .ball {
    width: 45px;
    height: 45px;
  }
  p {
    color: $white;
    margin-right: 3rem;
    &.iam {
      position: absolute;
      top: -4rem;
    }
  }
  img {
    height: 29px;
    margin-right: 2rem;
  }
  label {
    color: $white;
  }
}