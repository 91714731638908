#team-page {
  #team-hero {
    padding-top: 175px;
    @include media-breakpoint-up(lg) {
      padding-top: 250px;
    }
    h1 {
      color: $white;
    }
  }

  #team-people {
    margin-top: -100px;
  }

  .pattern {
    top: 115px;
  }

}