#documents-page {
  #documents-hero {
    padding-top: 175px;
    @include media-breakpoint-up(lg) {
      padding-top: 250px;
    }
  }
}

.breadcrumbs {
  margin-bottom: 2rem;
  a {
    color: $red;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.privacy-policy {
  padding-top: 175px;
  @include media-breakpoint-up(lg) {
    padding-top: 250px;
  }
}