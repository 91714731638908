#collab-page {
  background-image: url('~src/images/collab/collab-bg.jpeg');
  background-color: $black;
  background-repeat: no-repeat;
  background-size: cover;

  h2, p {
    color: $white;
  }

  #collab-hero {
    // height: 100vh;
    padding-top: 175px;
    @include media-breakpoint-up(lg) {
      height: 100vh;
      padding-top: 250px;
    }
    h1 {
      color: $white;
    }
  }
  .pattern {
    top: 0;
  }

  .collab-box {
    position: relative;
    .collab-box-text {
      padding-left: 50px;
    }
    a {
      display: flex;
    }
    p {
      font-size: 20px;
    }
    .kamil {
      height: 225px;
      width: 175px;
      background-image: url("~/src/images/team/kamil.jpeg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: $red;
      background-blend-mode: multiply;
    }
    .zebra-col {
      display: flex;
      align-items: flex-end;
      height: 125px;
    }
    .zebra {
      margin-left: 50px;
      width: 330px;
      height: 45px;
    }
    .linkedin {
      height: 25px;
    }
    .twitter {
      height: 25px;
    }
    .mail {
      margin-bottom: -5px;
      height: 31px;
    }
  }
}

