$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1539px
);

@import "~/node_modules/bootstrap/scss/functions";
@import "~/node_modules/bootstrap/scss/variables";
@import "~/node_modules/bootstrap/scss/mixins/breakpoints";

@import "~/node_modules/bootstrap/dist/css/bootstrap-grid.min.css";

@import "~/src/styles/variables.scss";

@import "~/src/styles/buttons.scss";
@import "~/src/styles/inputs.scss";

@import "~/src/styles/header.scss";
@import "~/src/styles/footer.scss";
@import "~/src/styles/social-media-widget.scss";

@import "~/src/styles/index.scss";
@import "~/src/styles/games.scss";
@import "~/src/styles/collab.scss";
@import "~/src/styles/utility.scss";
@import "~/src/styles/team.scss";
@import "~/src/styles/academy.scss";
@import "~/src/styles/legal-documents.scss";
@import "~/src/styles/facebook-data-deletion-instructions.scss";

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-ThinIta.eot");
  src: local("Helvetica Now Display Thin Ita"),
    local("HelveticaNowDisplay-ThinIta"),
    url("~/src/fonts/HelveticaNowDisplay-ThinIta.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-ThinIta.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-ThinIta.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-ThinIta.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-Light.eot");
  src: local("Helvetica Now Display Light"), local("HelveticaNowDisplay-Light"),
    url("~/src/fonts/HelveticaNowDisplay-Light.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-Light.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-Light.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-BoldIta.eot");
  src: local("Helvetica Now Display Bold Ita"),
    local("HelveticaNowDisplay-BoldIta"),
    url("~/src/fonts/HelveticaNowDisplay-BoldIta.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-BoldIta.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-BoldIta.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-BoldIta.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-RegIta.eot");
  src: local("Helvetica Now Display Reg Ita"),
    local("HelveticaNowDisplay-RegIta"),
    url("~/src/fonts/HelveticaNowDisplay-RegIta.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-RegIta.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-RegIta.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-RegIta.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-ExtLtIta.eot");
  src: local("Helvetica Now Display ExtLt Ita"),
    local("HelveticaNowDisplay-ExtLtIta"),
    url("~/src/fonts/HelveticaNowDisplay-ExtLtIta.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-ExtLtIta.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-ExtLtIta.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-ExtLtIta.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-MedIta.eot");
  src: local("Helvetica Now Display Med Ita"),
    local("HelveticaNowDisplay-MedIta"),
    url("~/src/fonts/HelveticaNowDisplay-MedIta.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-MedIta.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-MedIta.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-MedIta.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-ExtLt.eot");
  src: local("Helvetica Now Display ExtLt"), local("HelveticaNowDisplay-ExtLt"),
    url("~/src/fonts/HelveticaNowDisplay-ExtLt.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-ExtLt.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-ExtLt.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-ExtLt.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-ExtBlk.eot");
  src: local("Helvetica Now Display ExtBlk"),
    local("HelveticaNowDisplay-ExtBlk"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBlk.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBlk.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBlk.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBlk.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-Bold.eot");
  src: local("Helvetica Now Display Bold"), local("HelveticaNowDisplay-Bold"),
    url("~/src/fonts/HelveticaNowDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-Bold.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-Bold.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-ExtBdIta.eot");
  src: local("Helvetica Now Display ExtBd Ita"),
    local("HelveticaNowDisplay-ExtBdIta"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBdIta.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBdIta.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBdIta.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBdIta.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-Regular.eot");
  src: local("Helvetica Now Display Regular"),
    local("HelveticaNowDisplay-Regular"),
    url("~/src/fonts/HelveticaNowDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-Regular.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-Regular.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-ExtBlkIta.eot");
  src: local("Helvetica Now Display ExBlk It"),
    local("HelveticaNowDisplay-ExtBlkIta"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBlkIta.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBlkIta.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBlkIta.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-ExtBlkIta.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-Thin.eot");
  src: local("Helvetica Now Display Thin"), local("HelveticaNowDisplay-Thin"),
    url("~/src/fonts/HelveticaNowDisplay-Thin.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-Thin.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-Thin.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-LightIta.eot");
  src: local("Helvetica Now Display Light Ita"),
    local("HelveticaNowDisplay-LightIta"),
    url("~/src/fonts/HelveticaNowDisplay-LightIta.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-LightIta.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-LightIta.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-LightIta.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-ExtraBold.eot");
  src: local("Helvetica Now Display ExtBd"),
    local("HelveticaNowDisplay-ExtraBold"),
    url("~/src/fonts/HelveticaNowDisplay-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-ExtraBold.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-ExtraBold.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-Medium.eot");
  src: local("Helvetica Now Display Medium"),
    local("HelveticaNowDisplay-Medium"),
    url("~/src/fonts/HelveticaNowDisplay-Medium.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-Medium.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-Medium.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-HairlineI.eot");
  src: local("Helvetica Now Display Hairline"),
    local("HelveticaNowDisplay-HairlineI"),
    url("~/src/fonts/HelveticaNowDisplay-HairlineI.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-HairlineI.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-HairlineI.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-HairlineI.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-BlackIta.eot");
  src: local("Helvetica Now Display Black Ita"),
    local("HelveticaNowDisplay-BlackIta"),
    url("~/src/fonts/HelveticaNowDisplay-BlackIta.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-BlackIta.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-BlackIta.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-BlackIta.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-Black.eot");
  src: local("Helvetica Now Display Black"), local("HelveticaNowDisplay-Black"),
    url("~/src/fonts/HelveticaNowDisplay-Black.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-Black.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-Black.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("~/src/fonts/HelveticaNowDisplay-Hairline.eot");
  src: local("Helvetica Now Display Hairline"),
    local("HelveticaNowDisplay-Hairline"),
    url("~/src/fonts/HelveticaNowDisplay-Hairline.eot?#iefix") format("embedded-opentype"),
    url("~/src/fonts/HelveticaNowDisplay-Hairline.woff2") format("woff2"),
    url("~/src/fonts/HelveticaNowDisplay-Hairline.woff") format("woff"),
    url("~/src/fonts/HelveticaNowDisplay-Hairline.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: "Helvetica Now Display";
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  // @include media-breakpoint-up(xxl) {
  //   font-size: 16px;
  // }
}

section {
  position: relative;
  overflow: hidden;
  max-width: 100% !important;
  padding-top: 100px;
  padding-bottom: 100px;

  @include media-breakpoint-up(lg) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

.container-fluid {
  @include media-breakpoint-up(lg) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

p {
  margin-top: 0;

  br {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    br {
      display: block;
    }
  }
}

a {
  text-decoration: none;
  color: $black;
}

img {
  display: block;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 400;
}

h1 {
  font-size: 38px;
  line-height: 1.25;

  br {
    display: none;
  }

  @include media-breakpoint-up(sm) {
    font-size: 50px;
    line-height: 50px;

    br {
      display: block;
    }
  }

  @include media-breakpoint-up(xl) {
    font-size: 70px;
    line-height: 70px;
  }
}

h2 {
  text-transform: uppercase;
  font-size: 28px;

  br {
    display: none;
  }

  @include media-breakpoint-up(xl) {
    font-size: 40px;

    br {
      display: block;
    }
  }
}

h3 {
  text-transform: uppercase;
  font-size: 21px;

  @include media-breakpoint-up(sm) {
    font-size: 31px;
  }
}

ul {
  list-style-image: url("~/src/images/li.svg");

  p,
  li {
    padding-left: .5rem;
  }

  li {
    margin-bottom: 1rem;
  }
}

ol {
  li {
    margin-bottom: 1rem;
  }

  &.lower-alpha {
    list-style: lower-alpha;
  }
}

ol.nested-counter-list {
  counter-reset: item;

  li {
    display: block;

    &::before {
      content: counters(item, ".") ". ";
      counter-increment: item;
      font-weight: bold;
    }
  }

  ol {
    counter-reset: item;

    &>li {
      display: block;

      &::before {
        content: counters(item, ".") " ";
        counter-increment: item;
        font-weight: bold;
      }
    }
  }
}

// HELPERS

.position-relative {
  position: relative;
}

.w-10 {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 10%;
  }
}

.w-20 {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 20%;
  }
}

.w-30 {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 30%;
  }
}

.w-40 {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 40%;
  }
}

.w-50 {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.w-60 {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 60%;
  }
}

.w-70 {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 70%;
  }
}

.w-80 {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 80%;
  }
}

.w-90 {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 90%;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mb-50 {
  margin-bottom: 25px;

  @include media-breakpoint-up(xl) {
    margin-bottom: 50px;
  }
}

.mb-100 {
  margin-bottom: 50px;

  @include media-breakpoint-up(xl) {
    margin-bottom: 100px;
  }
}

.mb-200 {
  margin-bottom: 200px;
  // @include media-breakpoint-up(xl) {
  //   margin-bottom: 200px;
  // }
}

.mr-8 {
  margin-right: 8rem;
}

.text-black {
  color: $black !important;
}

.text-white {
  color: $white !important;
}

.text-red {
  color: $red !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.font-size-14 {
  font-size: 14px;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-900 {
  font-weight: 900;
}

.bg-black {
  h2 {
    color: $white;
  }

  p {
    color: $white;
  }
}

.bg-white {
  background: $white !important;
}

.bg-black {
  background: $black !important;
}

.bg-red {
  background: $red !important;
}

.mobile-overflow-hidden {
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    overflow: auto;
  }
}

// SLIDE TEXT WIDGET 

.slideTextTrigger {
  overflow-x: hidden;

  .animeTextLeft,
  .animeTextRight {
    font-size: 31px;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
      font-size: 38px;
    }
  }

  .animeTextLeft {
    transition: transform .25s ease;
    white-space: nowrap;
  }

  .animeTextRight {
    transition: transform .25s ease;
    white-space: nowrap;
  }
}

// PATTERN 

.pattern {
  position: absolute;
  width: calc(100% - 24px);
  height: calc(100% + 100px);
  background-image: url('~src/images/pattern.svg');
  background-position: center;

  &.pattern-dark {
    background-image: url('~src/images/pattern-dark.svg');
  }

  @include media-breakpoint-up(lg) {
    width: calc(100% - 100px);
    height: calc(100% + 200px);
    background-size: contain;
  }
}

// SPACER 

.spacer {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  @include media-breakpoint-up(sm) {
    height: 75px;
    flex-direction: row;
  }

  @include media-breakpoint-up(xl) {
    height: 100px;
  }

  img {
    width: 100%;
    height: 75px;
    object-fit: cover;

    @include media-breakpoint-up(sm) {
      height: 100%;
      width: 50%;
    }
  }

  .spacer-desc {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.25rem 12px;

    @include media-breakpoint-up(sm) {
      width: 50%;
      padding-left: 50px;
    }

    span {
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
      font-size: 21px;

      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
  }
}

// BOX WRAPPER 

.box-wrapper {
  .box {
    display: flex;
    align-items: flex-end;
    height: 170px;
    padding: 2rem;
    margin-top: 1rem;

    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

// TEAM WRAPPER 

.team-wrapper {
  width: 275px;
  max-width: 100%;
  text-transform: uppercase;
  margin: 0 0 4rem;

  h3 {
    font-size: 30px;
    margin-bottom: .5rem;
  }

  .box {
    position: relative;
    background: $red;

    p {
      font-size: 14px;
    }

    .team-member-photo {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: .75rem;
      position: relative;
      height: 325px;
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: $red;
      background-blend-mode: multiply;
      transition: background-color .3s ease;

      &:hover {
        background-color: transparent;
      }

      &.kamil {
        background-image: url("~/src/images/team/kamil.jpeg");
      }

      &.wiktor {
        background-image: url("~/src/images/team/wiktor.jpeg");
      }

      &.bartek {
        background-image: url("~/src/images/team/bartek.jpeg");
      }

      &.mike {
        background-image: url("~/src/images/team/mike.jpeg");
      }

      &.annbarass {
        background-image: url("~/src/images/team/annbarass.jpeg");
      }

      &.avatar {
        background-image: url("~/src/images/team/avatar.jpeg");
      }

      h3,
      p {
        color: $white;
        text-transform: uppercase;
      }

      h3 {
        font-size: 26px;
        margin-bottom: 0;
      }

      p {
        font-weight: 900;
      }
    }

    .box-footer {
      padding: 1.5rem .75rem 1.5rem;

      p {
        margin-bottom: 0;
      }

      .box-social-media {
        position: absolute;
        right: 0;
        bottom: 8.25rem;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          margin-right: .75rem;
        }

        img {
          height: 1rem;

          &.email {
            height: 1.35rem;
          }
        }
      }
    }
  }
}

// CONTACT BOX 

.contact-box {
  position: relative;
  background: $red;
  padding: 2rem;

  @include media-breakpoint-up(lg) {
    padding: 0;
    display: flex;
    height: 320px;
  }

  .contact-box-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-up(lg) {
      padding-left: 50px;
      width: 50%;
    }

    p {
      @include media-breakpoint-up(lg) {
        font-size: 20px;
      }
    }
  }

  .contact-box-contact {
    width: 100%;

    @include media-breakpoint-up(lg) {
      height: 225px;
      display: flex;
      width: 50%;
      padding-left: 50px;
    }

    .contact-box-image {
      height: 225px;
      width: 175px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: $red;
      background-blend-mode: multiply;
      margin-bottom: 1rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }

      &.kamil {
        background-image: url("~/src/images/team/kamil.jpeg");
      }

      &.wiktor {
        background-image: url("~/src/images/team/wiktor.jpeg");
      }
    }

    .linkedin {
      height: 25px;
    }

    .twitter {
      height: 25px;
    }

    .mail {
      margin-bottom: -5px;
      height: 31px;
    }
  }

  .contact-zebra {
    width: 330px;
    max-width: 100%;
    height: 45px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 0;
      left: 50px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.cky-consent-container {
  width: 535px !important;
}

.cky-btn-revisit-wrapper .cky-btn-revisit {
  margin: 0;
}