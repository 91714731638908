#hero {
  height: 100vh;
  width: 100%;
  background: $red;
  position: relative;

  .container-fluid {
    height: 100%;

    .row {
      height: 100%;
    }
  }

  h1 {
    display: flex;
    flex-direction: column;
    color: $white;
    z-index: 999;
    margin-bottom: 0;
    font-size: 80px;
    font-weight: 900;
    line-height: 80px;

    @include media-breakpoint-up(sm) {
      margin-top: -50px;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 50px;
      font-size: 100px;
      line-height: 100px;
    }
  }

  .hero-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    @include media-breakpoint-up(sm) {
      height: 75px;
      flex-direction: row;
    }

    @include media-breakpoint-up(xl) {
      height: 100px;
    }

    img {
      width: 100%;
      height: 75px;
      object-fit: cover;

      @include media-breakpoint-up(sm) {
        height: 100%;
        width: 50%;
      }
    }

    .hero-desc {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1.25rem 12px;

      @include media-breakpoint-up(sm) {
        width: 50%;
        padding-left: 50px;
      }

      span {
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        font-size: 21px;

        @include media-breakpoint-up(sm) {
          font-size: 24px;
        }
      }
    }
  }

}


#values {
  position: relative;
  height: 200vh;

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .25s ease;
  }

  .values-container {
    transform: translateY(100%);
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pattern {
    bottom: 0;
  }

  h2 {
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .value-box {
    position: relative;
    background: $red;
    width: 400px;
    display: flex;
    align-items: flex-end;
    height: 235px;
    padding: 2rem;

    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

#mobile-values {
  position: relative;

  .pattern {
    top: 0;
  }

  h2 {
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: $white;
  }

  .value-box {
    position: relative;
    background: $black;
    display: flex;
    align-items: flex-end;
    height: 235px;
    padding: 2rem;

    p {
      font-size: 14px;
      color: $white;
      margin-bottom: 0;
    }
  }
}

#about {
  img {
    width: 45px;
    margin-right: 1rem;
    transition: opacity .25s ease;

    @include media-breakpoint-up(xl) {
      width: 55px;
    }
  }

  h2 {
    font-weight: 900;
    text-transform: capitalize;
  }

  p {
    padding-left: calc(55px + 2rem);
    padding-right: 1.5rem;
    max-width: 100%;

    @include media-breakpoint-up(xl) {
      width: 350px;
      padding-right: 0;
      padding-left: calc(55px + 1rem);
    }
  }
}

#businessAreas {
  background: $white;

  @include media-breakpoint-up(lg) {
    height: 2500px;
  }

  .fade-in-text {
    margin: 50px 0 100px;
    font-size: 34px;
    line-height: 1.25;

    br {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      br {
        display: block;
      }
    }

    @include media-breakpoint-up(lg) {
      font-size: 64px;
      line-height: 64px;
    }
  }

  .mobile-balls-wrapper {
    .mobile-ball {
      position: relative;
      border-radius: 50%;
      background: $black;
      display: flex;
      width: 46px;
      height: 46px;
      margin-bottom: 1.5rem;
    }

    .mobile-ball-content {
      margin-bottom: 1.5rem;
    }
  }

  .balls-wrapper {
    margin-bottom: 150px;

    .ball {
      position: relative;
      border-radius: 50%;
      background: $black;
      display: flex;

      .ball-content {
        display: flex;
        align-items: center;
        position: absolute;
        width: 100vw;
      }
    }

    .ball-1 {
      width: 46px;
      height: 46px;

      .ball-content {
        top: -1.75rem;
        left: 2.5rem;
      }
    }

    .ball-2 {
      width: 124px;
      height: 124px;

      .ball-content {
        top: -0.5rem;
        left: 7rem;
      }
    }

    .ball-3 {
      width: 220px;
      height: 220px;

      .ball-content {
        top: 0;
        left: -29.5rem;
      }

      .placeholder {
        width: 220px;
        height: 220px;
      }

      p {
        width: 19rem;
      }
    }

    .ball-4 {
      width: 317px;
      height: 317px;

      .ball-content {
        top: 5.5rem;
        left: -47rem;
      }
    }
  }

  .ball-content-wrappers {
    position: relative;
    height: 140vh;
  }

  .ball-content-wrapper {
    position: absolute;
    display: flex;
    align-items: center;

    &:first-of-type {
      top: 0;
      left: 3rem;
    }

    &:nth-of-type(2) {
      top: 12rem;
      left: 24rem;
    }

    &:nth-of-type(3) {
      top: 25rem;
      left: 8.5rem;

      .placeholder {
        width: 220px;
        height: 220px;
      }

      p {
        width: 19rem;
      }
    }

    &:nth-of-type(4) {
      bottom: 8rem;
      right: 20rem;
    }

    h2 {
      line-height: 1;
    }

    p {
      margin: .5rem 0 0;
    }
  }
}

#core-team {
  margin-top: -1px;

  .pattern {
    top: 100px;
    height: calc(100% - 180px);
  }

  .core-team-box {
    position: relative;
    display: flex;
    flex-direction: column;
    background: $red;
    padding: 0;

    @include media-breakpoint-up(lg) {
      padding: 25px 50px;
    }

    .core-team-member {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: 1rem;
      height: 300px;
      width: 225px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: $red;
      background-blend-mode: multiply;
      transition: background-color .3s ease;

      @include media-breakpoint-up(lg) {
        height: 300px;
        width: 225px;
      }

      &:hover {
        background-color: transparent;
      }

      &.avatar {
        background-image: url("~/src/images/team/avatar.jpeg");
      }

      &.kamil {
        background-image: url("~/src/images/team/kamil.jpeg");
      }

      &.wiktor {
        background-image: url("~/src/images/team/wiktor.jpeg");
      }

      &.bartek {
        background-image: url("~/src/images/team/bartek.jpeg");
      }

      h3,
      p {
        color: $white;
        text-transform: uppercase;
      }

      h3 {
        font-size: 18px;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          font-size: 26px;
        }
      }

      p {
        font-size: 12px;
        font-weight: 900;

        @include media-breakpoint-up(lg) {
          font-size: 14px;
        }
      }
    }

  }

  .core-team-top,
  .core-team-bottom {
    width: 100%;
  }

  .core-team-top {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
      flex-direction: row;
      margin-bottom: 25px;
    }

    .core-team-copy {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        padding-top: 50px;
        padding-right: 50px;
        align-items: flex-end;
      }

      h2 {
        @include media-breakpoint-up(lg) {
          font-size: 70px;
          line-height: 1;
        }
      }
    }

    .core-team-zebra {
      display: none;
      width: 330px;
      height: 45px;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(lg) {
        display: block;
        margin-bottom: 0;
      }
    }
  }

  .core-team-bottom {
    justify-content: space-between;
    align-items: flex-end;

    span {
      font-size: 21px;
      font-weight: 700;
      text-transform: uppercase;

      a {
        color: $white;
        text-decoration: underline;
        font-weight: 900;
      }

      @include media-breakpoint-up(lg) {
        font-size: 30px;
      }
    }
  }

  .core-team-see-all {
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 50px;

    a {
      color: $white;
      text-decoration: underline;
      font-weight: 900;
    }

    @include media-breakpoint-up(lg) {
      font-size: 30px;
    }
  }
}