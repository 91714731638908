header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 12px 10px;
  z-index: 9999;
  transition: backdrop-filter .3s ease;
  transition: padding .3s ease;
  @include media-breakpoint-up(lg) {
    padding: 30px 50px 10px;
  }
  a, button {
    margin-right: 50px;
  }
  a {
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    margin-right: 20px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 400;
    color: $white;
    font-size: 16px;
    &:last-of-type {
      margin-right: 0;
    }
    &.active {
      background: $black;
      &::before {
        content: '';
        position: absolute;
        bottom: -32px;
        left: 0;
        height: 32px;
        background: $black;
        width: 100%;
      }
      &::after {
        content: '';
        position: absolute;
        top: -52px;
        left: 0;
        height: 52px;
        background: $black;
        width: 100%;
      }
    }
    &.collab.active, &.team.active, &.academy.active  {
      background: $red;
      &::before {
        background: $red;
      }
      &::after {
        background: $red;
      }
    }
  }
  &.header-dark {
    a {
      color: $black;
      &.active {
        color: $white;
      }
    }
    .logo, .hamburger {
      svg {
        path {
          fill: $black;
        }
      }
    }
  }
  .logo {
    position: relative;
    a {
      display: block;
      margin: 0;
      padding: 0;
    }
  }
  nav {
    display: none;
    align-items: center;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
  .hamburger {
    width: 33px;
    cursor: pointer;
  }
  &.blurred {
    backdrop-filter: blur(10px);
    padding-top: 10px;
    padding-bottom: 5px;
    a {
      &.active {
        &::before {
          bottom: -27px;
          height: 27px;
        }
      }
    }
  }
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    background: $black;
    width: 100%;
    height: 100vh;
    align-items: end;
    justify-content: center;
    padding-right: 30px;
    transition: transform .3s ease;
    .x {
      position: absolute;
      top: 46px;
      right: 30px;
      z-index: 0;
      height: 25px;
      width: 25px;
      svg {
        path {
          fill: $white;
        }
      }
    }
    &.hide {
      transform: translateX(100%);
    }
    .mobile-nav-link {
      font-size: 1.5rem;
      font-weight: 900;
      margin-right: 0;
      margin-bottom: 2.25rem;
      color: $white;
      padding: 0;
      &:last-of-type {
        margin-bottom: 3.5rem;
      }
    }
    a {
      svg {
        width: 30px;
        height: 30px;
        path {
          fill: $white;
        }
      }
    }
  }
}